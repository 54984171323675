// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-page-index-js": () => import("./../src/templates/ProductPage/index.js" /* webpackChunkName: "component---src-templates-product-page-index-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cart-js": () => import("./../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-custom-js": () => import("./../src/pages/custom.js" /* webpackChunkName: "component---src-pages-custom-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ready-js": () => import("./../src/pages/ready.js" /* webpackChunkName: "component---src-pages-ready-js" */)
}

