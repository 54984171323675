import React from 'react'
import Link from './core/Link'

const Logo = ({ children, ...props }) => (
  <Link
    color="black"
    fontSize={[5, 6, '56px']}
    fontFamily="serif"
    fontWeight="900"
    textDeecoration="none"
    letterSpacing="-3px"
    to="/"
    {...props}
  >
    Prospect.
  </Link>
)

export default Logo
