import React from 'react'
import styled from '@emotion/styled'
import { position } from 'styled-system'
import Link from './core/Link'

const Item = styled(Link)`
  ${position}
  text-transform: uppercase;
`

const NavItem = ({ children, ...props }) => (
  <Item
    ml={[3, 3, 0]}
    color="black"
    fontSize={[2, 3, 4]}
    position="relative"
    {...props}
  >
    {children}
  </Item>
)

export default NavItem
