import React from 'react'
import styled from '@emotion/styled'
import { Box } from 'reflexbox'
import { border, position } from 'styled-system'

const Outer = styled(Box)(border, position)

const Marker = ({ number }) => (
  <Outer
    bg="green"
    color="#FFF"
    borderRadius="100%"
    position="absolute"
    fontSize={11}
    top={-10}
    right={-25}
    size={20}
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    {number}
  </Outer>
)

export default Marker
