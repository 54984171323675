import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'emotion-theming'
import { Global } from '@emotion/core'

import StoreContextProvider from '~/provider/StoreContextProvider'
import Header from '~/components/Header'
import Footer from '../components/Footer'
import theme from '~/styles/theme'
import globalStyles from '~/styles/global'
import { Box } from 'reflexbox'

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <StoreContextProvider>
        <Global styles={globalStyles} />
        <Box bg="background" minHeight="100vh">
          <Header />
          {children}
          <Footer />
        </Box>
      </StoreContextProvider>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
