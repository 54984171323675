import React from 'react'

import Container from './ui/Container'
import Section from './ui/Section'
import { Flex, Box } from 'reflexbox'

const Footer = () => {
  return (
    <Container>
      <Section isTop>
        <Flex justifyContent="space-between">
          <Box>&copy; {new Date().getFullYear()} Prospect Leather</Box>
          <Box>
            <a href="http://instagram.com/prospectleather">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 18 18"
                height="18"
                width="18"
              >
                <path d="M9 1.622c2.403 0 2.688.009 3.637.052.877.04 1.354.187 1.671.31.42.163.72.358 1.035.673.315.315.51.615.673 1.035.123.317.27.794.31 1.671.043.949.052 1.234.052 3.637s-.009 2.688-.052 3.637c-.04.877-.187 1.354-.31 1.671-.163.42-.358.72-.673 1.035-.315.315-.615.51-1.035.673-.317.123-.794.27-1.671.31-.949.043-1.234.052-3.637.052s-2.688-.009-3.637-.052c-.877-.04-1.354-.187-1.671-.31a2.791 2.791 0 01-1.035-.673 2.784 2.784 0 01-.673-1.035c-.123-.317-.27-.794-.31-1.671-.043-.949-.052-1.234-.052-3.637s.009-2.688.052-3.637c.04-.877.187-1.354.31-1.671.163-.42.358-.72.673-1.035.315-.315.615-.51 1.035-.673.317-.123.794-.27 1.671-.31.95-.043 1.234-.052 3.637-.052zM9 0C6.556 0 6.25.01 5.29.054 4.33.098 3.676.25 3.104.473A4.408 4.408 0 001.51 1.51c-.5.5-.808 1.002-1.037 1.594C.25 3.677.098 4.33.054 5.289.01 6.25 0 6.556 0 9s.01 2.75.054 3.71c.044.959.196 1.613.419 2.185.23.592.537 1.094 1.038 1.595.5.5 1.002.808 1.594 1.038.572.222 1.227.374 2.185.418C6.25 17.99 6.556 18 9 18c2.445 0 2.751-.01 3.711-.054.958-.044 1.612-.196 2.185-.418a4.409 4.409 0 001.594-1.038c.5-.501.808-1.003 1.038-1.595.223-.572.375-1.226.418-2.184.044-.96.055-1.267.055-3.711s-.01-2.75-.055-3.71c-.043-.959-.195-1.613-.418-2.185A4.408 4.408 0 0016.49 1.51 4.41 4.41 0 0014.896.472C14.323.25 13.669.098 12.71.054 11.75.01 11.444 0 9 0z"></path>
                <path d="M9 4.379a4.621 4.621 0 100 9.242A4.621 4.621 0 009 4.38zm0 7.62A3 3 0 119 6a3 3 0 010 6zM13.804 5.276a1.08 1.08 0 100-2.16 1.08 1.08 0 000 2.16z"></path>
              </svg>
            </a>
          </Box>
        </Flex>
      </Section>
    </Container>
  )
}

export default Footer
