import React, { useContext } from 'react'
import { Flex, Box } from 'reflexbox'
import reduce from 'lodash/reduce'
import StoreContext from '~/context/StoreContext'

import Container from './ui/Container'
import NavItem from './ui/NavItem'
import Logo from './ui/Logo'
import Section from './ui/Section'
import Marker from './ui/Marker'

const useQuantity = () => {
  const {
    store: { checkout },
  } = useContext(StoreContext)
  const items = checkout ? checkout.lineItems : []
  const total = reduce(items, (acc, item) => acc + item.quantity, 0)
  return [total !== 0, total]
}

const Header = ({ siteTitle }) => {
  const [hasItems, quantity] = useQuantity()

  return (
    <Container>
      <Section>
        <Flex justifyContent="space-between" alignItems="center">
          <Box order={1}>
            <NavItem to="/about">About</NavItem>
          </Box>
          <Box order={[0, 0, 1]} flexGrow={[1, 1, 0]}>
            <Logo />
          </Box>
          <Box order={1}>
            <NavItem to="/cart">
              Cart {hasItems && <Marker number={quantity} />}
            </NavItem>
          </Box>
        </Flex>
      </Section>
    </Container>
  )
}

export default Header
