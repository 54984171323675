import { css } from '@emotion/core'

import {
  ProspectusBlack,
  ProspectusBlackItalic,
  ProspectusBold,
  ProspectusBoldItalic,
  MissionGothic,
  MissionGothicBold,
  MissionGothicBoldItalic,
  MissionGothicItalic,
} from './fonts'
import { Leather, Dimensions, Cash, Card, Stamp, Gator, Hardware } from './icons'

const globalStyles = css`
  @font-face {
    font-family: 'Prospectus';
    src: url(${ProspectusBlack}) format('woff2');
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'Prospectus';
    src: url(${ProspectusBlackItalic}) format('woff2');
    font-weight: 900;
    font-style: italic;
  }

  @font-face {
    font-family: 'Prospectus';
    src: url(${ProspectusBold}) format('woff2');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Prospectus';
    src: url(${ProspectusBoldItalic}) format('woff2');
    font-weight: 600;
    font-style: italic;
  }

  @font-face {
    font-family: 'Mission Gothic';
    src: url(${MissionGothicBold}) format('woff2');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Mission Gothic';
    src: url(${MissionGothicItalic}) format('woff2');
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: 'Mission Gothic';
    src: url(${MissionGothicBoldItalic}) format('woff2');
    font-weight: bold;
    font-style: italic;
  }

  @font-face {
    font-family: 'Mission Gothic';
    src: url(${MissionGothic}) format('woff2');
    font-weight: normal;
    font-style: normal;
  }

  html {
    font-family: 'Mission Gothic', Frutiger, 'Frutiger Linotype', Univers,
      Calibri, 'Gill Sans', 'Gill Sans MT', 'Myriad Pro', Myriad,
      'DejaVu Sans Condensed', 'Liberation Sans', 'Nimbus Sans L', Tahoma,
      Geneva, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  body {
    margin: 0;
  }

  * {
    box-sizing: border-box;
  }

  p {
    margin-top: 0;
  }

  a {
    color: #000;
    text-decoration: none;
  }

  .speclist {
    margin: 0;
    margin-top: 25px;
    padding: 0;
    font-size: 16px;
    display: flex;
    flex-wrap: wrap;
  }

  .icon {
    display: block;
    padding-left: 30px;
    margin: 0;
    position: relative;
    margin-bottom: 10px;
    width: 100%;
    @media (min-width: 40em) {
      width: 50%;
    }
  }

  .icon::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 1.5em;
    width: 1.25em;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .icon--leather::after {
    background-image: url(${Leather});
  }
  .icon--dimensions::after {
    background-image: url(${Dimensions});
  }
  .icon--cash::after {
    background-image: url(${Cash});
  }
  .icon--gator::after {
    background-image: url(${Gator});
  }
  .icon--card::after {
    background-image: url(${Card});
  }
  .icon--stamp::after {
    background-image: url(${Stamp});
  }
  .icon--hardware::after {
    background-image: url(${Hardware});
  }
`

export default globalStyles
