import styled from '@emotion/styled'
import shouldForwardProp from '@styled-system/should-forward-prop'
import { space, color, border, typography, layout } from 'styled-system'
import { Link as LinkComponent } from 'gatsby'

const Link = styled(LinkComponent, {
  shouldForwardProp,
})(space, color, border, typography, layout)

export default Link
