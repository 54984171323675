const theme = {
  colors: {
    background: '#FDFBF4',
    black: '#111111',
    highlight: '#F5F5EB',
    hover: '#EFEEE6',
    green: '#1F9A1D',
    blue: '#0262D3',
    red: '#0262D3',
    keyline: 'rgba(0,0,0, 0.1)',
  },
  fonts: {
    serif: '"Prospectus", Constantia, "Lucida Bright", Lucidabright, "Lucida Serif", Lucida, "DejaVu Serif", "Bitstream Vera Serif", "Liberation Serif", Georgia, serif;',
    sans: '"Mission Gothic", Frutiger, "Frutiger Linotype", Univers, Calibri, "Gill Sans", "Gill Sans MT", "Myriad Pro", Myriad, "DejaVu Sans Condensed", "Liberation Sans", "Nimbus Sans L", Tahoma, Geneva, "Helvetica Neue", Helvetica, Arial, sans-serif'
  }
}

export default theme
