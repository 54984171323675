import React from 'react'
import { Box } from 'reflexbox'

const Container = ({ children }) => (
  <Box maxWidth={1440} mx="auto" px={[3, 5, 6]}>
    {children}
  </Box>
)

export default Container
