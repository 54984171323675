import React from 'react'
import styled from '@emotion/styled'
import { border } from 'styled-system'
import { Box } from 'reflexbox'

const Wrapper = styled(Box)`
  ${border}
`

const Section = ({ children, isTop }) => (
  <Wrapper
    borderBottom={!isTop && '3px solid'}
    borderTop={isTop && '3px solid'}
    borderColor="keyline"
    py={[3, 4]}
  >
    {children}
  </Wrapper>
)

export default Section
